import { useEffect, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getDefaultLanguage } from "utility";
import { useContext } from "react";
import UserContext from "stores/user.context";
import LayoutContext from "stores/layout.context";

const pages = [
  {
    path: `signin-signup`,
    componentPath: "signin-signup",
    checkLoginStatus: true,
  },
  {
    path: `forget-password`,
    componentPath: "forget-password",
    checkLoginStatus: true,
  },
  {
    path: `reset-password`,
    componentPath: "reset-password",
    checkLoginStatus: true,
  },
  { path: `cheques/:cheuqeId/:phone`, componentPath: "cheuqe-state" },
  { path: `cheques/:cheuqeId`, componentPath: "cheuqe-state" },
  { path: `cheques`, componentPath: "cheuqe-state" },
  { path: `policy/qrCode`, componentPath: "qrCode" },
  { path: `validate-otp`, componentPath: "validate-otp", protected: true },
  { path: `terms`, componentPath: "terms" },
  {
    path: `create-policy-travel`,
    componentPath: "create-policy",
    protected: true,
  },
  {
    path: `create-policy-personalguard`,
    componentPath: "create-policy-personalguard",
    protected: true,
  },
  {
    path: `create-policy-darna`,
    componentPath: "create-policy-darna",
    protected: true,
  },
  {
    path: `car-insurance-renewal`,
    componentPath: "car-insurance-renewal",
    protected: true,
  },
  { path: `dashboard`, componentPath: "dashboard/main", protected: true },
  {
    path: `dashboard/contact-us`,
    componentPath: "dashboard/contact-us",
    protected: true,
  },
  {
    path: `dashboard/find-us`,
    componentPath: "dashboard/find-us",
    protected: true,
  },
  { path: `offers`, componentPath: "dashboard/offers", protected: true },
  {
    path: `offers/:offerId`,
    componentPath: "dashboard/offer",
    protected: true,
  },
  {
    path: `profile/personal-info`,
    componentPath: "profile/personal-info",
    protected: true,
  },
  {
    path: `profile/login-info`,
    componentPath: "profile/login-info",
    protected: true,
  },
  {
    path: `policies/:policyId`,
    componentPath: "dashboard/policies",
    protected: true,
  },
  { path: `payment-result`, componentPath: "payment-result", protected: true },
  { path: `renewal`, componentPath: "company-renewal", protected: true },
  {
    path: `create-policy-personalguard/accident-failed`,
    componentPath: "personalGuard-accident-failed",
    protected: true,
  },
  {
    path: `lababak-service`,
    componentPath: "lababak-service",
    protected: true,
  },
  {
    path: `create-lababak-appointment`,
    componentPath: "create-lababak-appointment",
    protected: true,
    customerCare: true,
  },
  {
    path: `appointment-preview-c`,
    componentPath: "appointment-preview",
    protected: true,
    customerCare: true,
  },
  {
    path: `appointment-preview`,
    componentPath: "appointment-preview",
    protected: true,
  },
  {
    path: `create-policy-personalguard/accident-failed`,
    componentPath: "personalGuard-accident-failed",
    protected: true,
  },
  {
    path: `lababak-service`,
    componentPath: "lababak-service",
    protected: true,
  },
  {
    path: `create-lababak-appointment`,
    componentPath: "create-lababak-appointment",
    protected: true,
    customerCare: true,
  },
  {
    path: `account-suspended`,
    componentPath: "account-suspended",
    protected: true,
    isSuspend: true,
  },
  {
    path: `account-review`,
    componentPath: "account-review",
    protected: true,
    isReview: true,
  },
];
//////////////////////////////
const generateRoutes = (pages, user, lang) => {
  return pages.map((routeElem) => {
    let C = lazy(() =>
      import(
        /* webpackChunkName: "[request]" */ `../pages/${routeElem.componentPath}`
      )
    );
    return pages.map((routeElem) => {
      let C = lazy(() =>
        import(
          /* webpackChunkName: "[request]" */ `../pages/${routeElem.componentPath}`
        )
      );
      return (
        <Route
          key={routeElem.path}
          path={`/:lang/${routeElem.path}`}
          exact={true}
          render={(props) => {
            if (
              (routeElem.checkLoginStatus || routeElem.customerCare) &&
              user &&
              user.type === "customer"
            ) {
              return <Redirect to={`/${lang}/dashboard`} />;
            }
            if (
              routeElem.checkLoginStatus &&
              user &&
              user.type === "customerCare"
            ) {
              return <Redirect to={`/${lang}/create-lababak-appointment`} />;
            }
            if (
              (routeElem.checkLoginStatus || !routeElem.customerCare) &&
              user &&
              user.type === "customerCare"
            ) {
              return <Redirect to={`/${lang}/create-lababak-appointment`} />;
            }
            if (routeElem.protected && !user) {
              return <Redirect to={`/${lang}/signin-signup`} />;
            }
            return (
              <>
                <C {...props} />
              </>
            );
          }}
        />
      );
    });
  });
};
//////////////////////////////
const Routes = (props) => {
  const Common404 = lazy(() =>
    import(/* webpackChunkName: "[404]" */ `../pages/404`)
  );
  const Home = lazy(() =>
    import(/* webpackChunkName: "[signinsignup]" */ `../pages/signin-signup`)
  );
  const { user } = useContext(UserContext);
  const { lang, setLanguage } = useContext(LayoutContext);
  const defaultLang = lang ? lang : getDefaultLanguage().lang;
  const routeElements = generateRoutes(pages, user, lang);

  // const [redirect,setRedirect]=useState(null)

  useEffect(() => {
    if (!lang) {
      setLanguage(getDefaultLanguage().lang);
      setLanguage(getDefaultLanguage().lang);
    }
  }, [lang, setLanguage]);

  return (
    <>
      <Switch>
        <Route
          path={`/${lang}/policies/travel/skyplus`}
          render={(props) => {
            return window.location.replace(
              "/assets/travel_insurance_sky_plus.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/medical/medicalnetwork`}
          render={(props) => {
            fetch("/assets/jerusalem_insurance.xlsx")
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "jerusalem_insurance.xlsx");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              })
              .catch((error) => {
                console.log("Error downloading the file:", error);
              });
            // return ( window.location.replace('/assets/jerusalem_insurance.xlsx'))
          }}
        />
        <Route
          path={`/${lang}/brochure/medical/curein/en`}
          render={(props) => {
            return window.location.replace(
              "/assets/medical_insurance_CURE_IN_En.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/brochure/medical/curein/ar`}
          render={(props) => {
            return window.location.replace(
              "/assets/medical_insurance_CURE_IN_Ar.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/brochure/medical/cure50/en`}
          render={(props) => {
            return window.location.replace(
              "/assets/medical_insurance_CURE_50.50_EN.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/brochure/medical/cure50/ar`}
          render={(props) => {
            return window.location.replace(
              "/assets/medical_insurance_CURE_50.50_Ar.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/travel/sky`}
          render={(props) => {
            return window.location.replace("/assets/travel_insurance_sky.pdf");
          }}
        />
        <Route
          path={`/${lang}/brochure/travel/skyplus`}
          render={(props) => {
            return window.location.replace("/assets/sky_plus_brochureV2.pdf");
          }}
        />
        <Route
          path={`/${lang}/brochure/travel/sky`}
          render={(props) => {
            return window.location.replace("/assets/sky_brochureV2.pdf");
          }}
        />
        <Route
          path={`/${lang}/form/travel`}
          render={(props) => {
            return window.location.replace(
              "/assets/travel_application_form.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/travel/etihad/sky`}
          render={(props) => {
            return window.location.replace(
              "/assets/travel_insurance_sky-etihad_bank.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/travel/etihad/skyplus`}
          render={(props) => {
            return window.location.replace(
              "/assets/sky_plus_brochureV2.pdf"
            );
          }}
        />
                <Route
          path={`/${lang}/policies/travel/capital/sky`}
          render={(props) => {
            return window.location.replace(
              "/assets/travel_insurance_sky.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/travel/capital/skyplus`}
          render={(props) => {
            return window.location.replace(
              "/assets/travel_insurance_sky_plus.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/privacy/policy`}
          render={(props) => {
            return window.location.replace(
              "/assets/privacy_policy_for_jerusalem_insurance.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/motor/comprehensive`}
          render={(props) => {
            return window.location.replace("/assets/comprehensive_policy.pdf");
          }}
        />
        <Route
          path={`/${lang}/policies/motor/mumayaz`}
          render={(props) => {
            return window.location.replace("/assets/mumayaz_policy.pdf");
          }}
        />
        <Route
          path={`/${lang}/brochure/motor/mumayaz/en`}
          render={(props) => {
            return window.location.replace(
              "/assets/mumayaz_motor_brochure_en.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/brochure/motor/mumayaz/ar`}
          render={(props) => {
            return window.location.replace("/assets/mumayaz_motor_brochure_ar.pdf");
          }}
        />
        <Route
          path={`/${lang}/policies/personalguard`}
          render={(props) => {
            return window.location.replace(
              "/assets/personal_guard_policy_terms_and_conditions.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/darna/form/ar`}
          render={(props) => {
            return window.location.replace("/assets/darna_form_AR.pdf");
          }}
        />
        <Route
          path={`/${lang}/policies/darna/form/en`}
          render={(props) => {
            return window.location.replace("/assets/darna_form_EN.pdf");
          }}
        />
        <Route
          path={`/${lang}/policies/darna/brochure/ar`}
          render={(props) => {
            return window.location.replace(
              "/assets/darna_program_brochure_AR.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/darna/brochure/en`}
          render={(props) => {
            return window.location.replace(
              "/assets/darna_program_brochure_EN.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/darna/capital`}
          render={(props) => {
            return window.location.replace(
              "/assets/darna_terms_and_condition.pdf"
            );
          }}
        />
        <Route
          path={`/${lang}/policies/darna`}
          render={(props) => {
            return window.location.replace(
              "/assets/darna_terms_and_condition.pdf"
            );
          }}
        />
        <Route
          path={`/`}
          exact={true}
          render={(props) => {
            if (user) {
              return <Redirect to={`/${lang}/dashboard`} />;
            }
            return <Redirect to={`/${defaultLang}//`} replace={true} />;
          }}
        />

        {routeElements}
        <Route
          path={`/:lang/`}
          exact={true}
          render={(props) => {
            if (user && user.type === "customer") {
              return <Redirect to={`/${lang}/dashboard`} />;
            } else if (user && user.type === "customerCare") {
              return <Redirect to={`/${lang}/create-lababak-appointment`} />;
            }
            return (
              <>
                <Home {...props} />
              </>
            );
          }}
        />

        <Route component={Common404} />
      </Switch>
    </>
  );
};
export default Routes;
